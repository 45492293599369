import { Container, Button } from 'react-bootstrap';
import React from 'react';
import { AppContext } from '../components/AppContext';
import { Link, useNavigate } from 'react-router-dom';
import { logout } from '../http/userApi';

const User = () => {
  const { user } = React.useContext(AppContext);
  const navigate = useNavigate();

  const handleLogout = (event) => {
    logout();
    user.logout();
    navigate('/login', { replace: true });
  };

  return (
    <Container>
      <h1>Личный кабинет</h1>
      <p>Это личный кабинет постоянного покупателя магазина</p>
      <ul>
        <li>
          <Link to="/user/orders">История заказов</Link>
        </li>
      </ul>
      <Button onClick={handleLogout}>Выйти</Button>
    </Container>
  );
};

export default User;
