import React from 'react';
import Form from '../components/Form';

function FullEvent({ onClickApplication }) {
  const [openForm, setOpenForm] = React.useState(false);
  onClickApplication = () => {
    setOpenForm(true);
  };

  return (
    <div className="fullevents">
      <div className="container">
        <div className="fullevents__title">Наши мероприятия</div>
        <div className="fullevents__content">
          <div className="fullevents__item">
            <span>Винное казино</span> - Игроки собираются за зеленым сукном, ведущий вечера
            разливает в бокалы вина и попутно делает подсказки и абстрактно рассказывает про
            различные аспекты виноделия. Ваша задача: ставить фишки на предполагаемые сорта, года и
            регионы, используя имеющиеся знания или те, которые вам дает ведущий вечера по ходу
            игры. Винное казино-это отличный шанс не только вкусно и много выпить, но еще и узнать
            много нового про вино и виноделие. Можно играть с любым уровнем знаний о вине. Сет
            выбирается под каждую компанию индивидуально с учетом всех пожеланий и уровня знаний.
            Стоимость рассчитывается индивидуально с учетом пожеланий Минимальное количество
            игроков: 5
          </div>
          <div className="fullevents__item">
            <span>КВИЗ. Алкогольно-интеллектуальная игра</span> - Игроки делятся на команды (2,3 или
            4 в зависимости от количества игроков). Ведущая вечера разливает по бокалам вина (на
            протяжении всей игры) и открывает полотно вопросов. Игра строится по типу «Своей игры»,
            все вопросы делятся на категории, которые в свою очередь распределены по сложности
            («История за 100», «Кино за 300»). Выигрывает и получает приз та команда, которая
            набирает больше всего очков! Отличный вариант для корпоратива и дня рождения (проверено
            ни раз!) Стоимость рассчитывается индивидуально с учетом пожеланий Минимальное
            количество игроков: 8
          </div>
          <div className="fullevents__item">
            <span>Дегустация вин</span> - Профессиональный винный эксперт подбирает сет вин,
            основываясь на ваших вкусах и пожеланиях и проводит презентацию. Отличная возможность
            как для новичков (разобраться в самых азах винной культуры, найти свои любимые сорта,
            определиться со вкусами, научиться выбирать вино), так и для любителей (попробовать
            новые вина, пополнить знания) Стоимость рассчитывается индивидуально с учетом пожеланий
            Минимальное количество участников: 2
          </div>
          <div className="fullevents__item">
            <span>Вино и футбол</span> - не совсем стандартное сочетание, однако у нас в 'Русском
            вине' очень любят эксперементы. Смотрите свою любимую игру с бокалом вина в окружение
            поклонников футбола, вход свободный, надо только купить бутылку российского вина
          </div>
        </div>
        <button onClick={onClickApplication} className="fullevents__button">
          Отправить заявку на мероприятия
        </button>
        {openForm && (
          <div className="overlay">
            <div onClick={() => setOpenForm(false)} className="closed">
              <img src="../img/closed.png" alt="closed" />
            </div>
            <Form />
          </div>
        )}
      </div>
    </div>
  );
}

export default FullEvent;
